import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Text } from "components";
import { Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getDefaultLanguage } from "../../utils/helper/custom";
import { useTranslation } from "react-i18next";
import { LogoutModal } from "popups/LogoutModal";
import { ChooseLocation } from "popups/ChooseLocation";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { USERS } from "../../utils/helper/Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { AddEditAddress } from "popups/AddEditAddress";
import * as CUSTOM from "../../utils/helper/custom";
import { currentTrigger, changeTrigger } from "redux/reducers/trigger";
import * as APIS from "../../utils/helper/Enum";
import { getAuth } from "../../redux/reducers/loginData";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../utils/global/localData";

const TopHeader = (props) => {
  const navigate = useNavigate();
  let auth = useSelector(getAuth);
  let triggerValue = useSelector(currentTrigger);
  const dispatch = useDispatch();
  const [headerAddressList, setHeaderAddressList] = useState({});
  const [defaultAddress, setDefaultAddress] = useState("");
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [language, setLanguage] = useState(false);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [LogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [ChooseLocationModalOpen, setChooseLocationModalOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handlelanguage = () => {
    setLanguage(!language);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const enStyles = !language ? "hidden" : "block";

  const handelsetLogoutModalOpen = () => {
    setAnchorEl(null);
    setLogoutModalOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };
  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };
  
  const handelsetChooseLocationModalOpen = () => {
    if (auth?.id == 0) {
      setAddAddressPopup(true);
    } else {
      setChooseLocationModalOpen(true);
    }
    document.body.classList.add("overflow-hidden");
  };

  /**
   *  Get user Saved Addresses
   */
  const getSavedAddress = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        USERS?.GET_ALL_ADDRESS,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setHeaderAddressList(response?.data);
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   *  Get guest saved Addresses
   */
  const getSavedGuestAddress = async () => {
    try {
      const guestId = CUSTOM.getDeviceID();
      let response = await globalRequest(
        APIS?.GUEST?.GET_ADDRESS_LIST,
        "get",
        {},
        { params: { guest_id: guestId } },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        //let gaddress = [];
        // gaddress.push(response?.data);
        return response?.data;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (auth?.id) {
      getSavedAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ChooseLocationModalOpen, addAddressPopup, auth]);

  useEffect(() => {
    //setDefaultAddress
    if (Object.keys(headerAddressList).length > 0 && auth?.id) {
      let address = headerAddressList?.userAddresses?.find(
        (item) => item?.id === headerAddressList?.defaultAddressId
      );
      setTimeout(() => {
        if (address) {
          setDefaultAddress(CUSTOM.buildFullAddress(address));
        } else {
          setDefaultAddress(CUSTOM.getDefautAddress()?.address);
        }
      }, 500);
    }
  }, [headerAddressList, auth]);

  useEffect(() => {
    const executeCode = async () => {
      if (auth?.id == 0 && triggerValue != "loggedin") {
        setDefaultAddress(CUSTOM.getDefautAddress()?.address);
        let addressData = addDeleteGetLocalStorage(
          storageKeys.GUEST_ADDRESS,
          {},
          "get"
        );
        if (addressData) {
          addressData = JSON.parse(addressData);
          let address = {
            streetNumber: addressData?.street_number,
            buildingNumber: addressData?.building_number,
            floorApartment: addressData?.floor_apartment,
            address: addressData?.address,
          };
          setDefaultAddress(CUSTOM.buildFullAddress(address));
        } else {
          let getAddress = !auth?.id ? await getSavedGuestAddress() : false;
          if (getAddress) {
            getAddress.street_number = getAddress?.streetNumber;
            getAddress.building_number = getAddress?.buildingNumber;
            getAddress.floor_apartment = getAddress?.floorApartment;
            getAddress.zone_number = getAddress?.zoneNumber;
            getAddress.mobile_no = (getAddress?.mobileNo).split(" ")[1];
            getAddress.full_name = getAddress?.fullName;

            addDeleteGetLocalStorage(
              storageKeys.GUEST_ADDRESS,
              getAddress,
              "add",
              "single"
            );
          } else {
            const getUserLocation = () => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  async (position) => {
                    const { latitude, longitude } = position.coords;
                    let response = await globalRequest(
                      `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}`,
                      "get",
                      {},
                      {},
                      true
                    );
                    function xmlStringToObject(xmlString) {
                      const parser = new DOMParser();
                      const xmlDoc = parser.parseFromString(
                        xmlString,
                        "text/xml"
                      );
                      const result = {};

                      function parseNode(node) {
                        if (node.nodeType === 3) {
                          // Text node
                          return node.nodeValue.trim();
                        }

                        const obj = {};
                        if (node.attributes) {
                          for (let i = 0; i < node.attributes.length; i++) {
                            const attribute = node.attributes[i];
                            obj[attribute.nodeName] = attribute.nodeValue;
                          }
                        }

                        for (let i = 0; i < node.childNodes.length; i++) {
                          const childNode = node.childNodes[i];
                          const nodeName = childNode.nodeName;
                          const childValue = parseNode(childNode);

                          if (obj[nodeName]) {
                            if (!Array.isArray(obj[nodeName])) {
                              obj[nodeName] = [obj[nodeName]];
                            }
                            obj[nodeName].push(childValue);
                          } else {
                            obj[nodeName] = childValue;
                          }
                        }

                        return obj;
                      }

                      result[xmlDoc.documentElement.nodeName] = parseNode(
                        xmlDoc.documentElement
                      );

                      return result;
                    }
                    const resultObject = xmlStringToObject(response?.data);
                    setDefaultAddress(
                      resultObject?.reversegeocode?.result?.["#text"]
                    );
                  },
                  (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                      setDefaultAddress(CUSTOM.getDefautAddress()?.address);
                    } else {
                      setDefaultAddress(CUSTOM.getDefautAddress()?.address);
                    }
                  }
                );
              } else {
                setDefaultAddress(CUSTOM.getDefautAddress()?.address);
              }
            };
            getUserLocation();
            // setDefaultAddress(CUSTOM.getDefautAddress()?.address);
          }
        }
      }
    };
    executeCode();
  }, [auth, addAddressPopup, triggerValue]);

  return (
    <>
      <div className="bg-pink-100 flex md:flex-col flex-row md:gap-5 items-center justify-start w-full relative z-[31]">
        <div className="flex flex-row items-center justify-between w-full max-w-[1110px] mx-auto py-2 md:px-4">
          <div
            className={`flex flex-row items-center justify-center w-auto ${
              props?.cpage == "/cart" ? "visiblity-hidden" : ""
            }`}
            onClick={() => {
              handelsetChooseLocationModalOpen();
            }}
          >
            <Img
              className="h-6 w-6 cursor-pointer"
              src="/images/img_location.svg"
              alt="location"
            />
            {}
            <Text className="mx-1 text-black-900 text-sm font-nunitoregular font-normal line-clamp-1 max-w-[350px] sm:max-w-[250px] cursor-pointer">
              {defaultAddress || props?.riyadh}
            </Text>
          </div>

          <div className="flex flex-row items-center justify-end w-auto flex-1">
            <div
              className={`flex flex-row items-start justify-center w-auto 
                `}
              //   ${
              //   props?.cpage == "/cart" ? "visiblity-hidden" : ""
              // }
            >
              <Text
                className="text-black-900 text-right text-sm xs:hidden hidden"
                size="txtnunitoRegular14Black900"
              >
                {props?.callusforany}
              </Text>
              <Text
                className="ltr:ml-1 rtl:mr-1 text-black-900 text-right text-sm xs:hidden hidden"
                size="txtnunitoRegular14Black900"
              >
                {props?.userphonenumber}
              </Text>
              <Text
                className="ml-3 rtl:ml-0 rtl:mr-3 xs:ml-[0] text-black-900 xs:hidden text-sm hidden"
                size="txtnunitoRegular14Black900"
              >
                {props?.one}
              </Text>
              <div
                className="px-3 text-black-900 text-sm cursor-pointer mt-[2px] relative"
                size="txtnunitoRegular14Black900"
                onClick={handlelanguage}
              >
                {getDefaultLanguage("capital")}
                <div
                  className={`${enStyles} bg-pink-800 h-1 w-full absolute -bottom-[10px] left-0 z-50`}
                ></div>
                <div
                  className={`${enStyles} absolute left-auto top-[30px] -right-10 z-50 before-click`}
                >
                  <div className="w-auto flex flex-row bg-white-A700 shadow-md mx-auto">
                    <div className="flex flex-col py-2 w-full">
                      <ul className="flex flex-col">
                        <li
                          className="flex items-center gap-2 px-4 py-2 text-black-900 hover:bg-gray-50_02 hover:text-pink-800"
                          onClick={(e) => {
                            localStorage.setItem(
                              "jsahdjkanbn",
                              "sd542s3ad2sa1d3iu748923DSF"
                            );
                            i18n.changeLanguage("en");
                            let defaultLanguage = "ltr";
                            document.documentElement.setAttribute(
                              "dir",
                              defaultLanguage
                            );
                            if (props?.cpage == "/cart") {
                              dispatch(changeTrigger("updateinlocal_langChg"));
                            }
                            setTimeout(() => {
                              if (props?.cpage == "/cart") {
                                const url = new URL(window.location.href);
                                url.searchParams.set("lang", "1");
                                window.history.pushState(
                                  {},
                                  "",
                                  url.toString()
                                );
                              }
                              window.location.reload();
                            }, 500);
                          }}
                        >
                          <Img
                            className="h-6 object-contain w-6 min-w-[1.5rem]"
                            src="/images/flag-eng.svg"
                            alt="rabbitholelogo"
                          />
                          <Text className="whitespace-nowrap font-nunitoregular font-normal text-base leading-6 -mt-1 self-center">
                            English
                          </Text>
                        </li>
                        <li
                          className="flex items-center gap-2 px-4 py-2 text-black-900 hover:bg-gray-50_02 hover:text-pink-800"
                          onClick={(e) => {
                            localStorage.setItem(
                              "jsahdjkanbn",
                              "sd5s42s3ad2sa1d3iu748923DSF"
                            );
                            i18n.changeLanguage("ar");
                            let defaultLanguage = "rtl";
                            document.documentElement.setAttribute(
                              "dir",
                              defaultLanguage
                            );
                            if (props?.cpage == "/cart") {
                              dispatch(changeTrigger("updateinlocal_langChg"));
                            }
                            setTimeout(() => {
                              if (props?.cpage == "/cart") {
                                const url = new URL(window.location.href);
                                url.searchParams.set("lang", "1");
                                window.history.pushState(
                                  {},
                                  "",
                                  url.toString()
                                );
                              }
                              window.location.reload();
                            }, 500);
                          }}
                        >
                          <Img
                            className="h-6 object-contain w-6 min-w-[1.5rem]"
                            src="/images/flag-arb.svg"
                            alt="rabbitholelogo"
                          />
                          <Text className="whitespace-nowrap font-nunitoregular font-normal	text-base leading-6 -mt-1 self-center">
                            عربي
                          </Text>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <Text
                className="text-black-900 text-sm"
                size="txtnunitoRegular14Black900"
              >
                {props?.two}
              </Text>
            </div>

            {auth?.id ? (
              <>
                <Button
                  className="pl-3 rtl:pl-0 rtl:pr-3 text-black-900 text-sm relative font-nunitoregular font-normal text-[14px] mb-[-6px] h-full flex -mt-1 overflow-visible"
                  id="profilemenu-button"
                  aria-controls={open ? "profilemenu-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  rightIcon={<KeyboardArrowDownIcon />}
                >
                  <span className="block xs:hidden">Hi, {auth?.firstName}</span>
                  <Img
                    className="h-4 w-4 min-w-[16px] mt-1 hidden sm:block"
                    src="/images/person.svg"
                    alt="rabbitholelogo"
                  />
                  <div
                    className={`hidden profilemenu-button-line bg-pink-800 h-1 w-full absolute -bottom-[7px] left-0 right-0 m-auto z-50 max-w-[90px]`}
                  ></div>
                </Button>
                <Menu
                  id="profilemenu-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "profilemenu-button",
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={(e) => {
                      navigate("/my-order");
                      handleClose();
                    }}
                  >
                    {t("Profile")}
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      navigate("/my-order");
                      handleClose();
                    }}
                  >
                    {t("orders")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handelsetLogoutModalOpen();
                      handleClose();
                    }}
                  >
                    {t("Logout")}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Text
                className="ml-3 rtl:ml-0 rtl:mr-3 text-black-900 text-sm cursor-pointer"
                size="txtnunitoRegular14Black900"
                onClick={() => navigate("/login")}
              >
                {props?.Signup_Login}
              </Text>
            )}
          </div>
        </div>
      </div>

      {LogoutModalOpen === true ? (
        <LogoutModal closepopup={setLogoutModalOpen} />
      ) : null}
      {ChooseLocationModalOpen === true ? (
        <ChooseLocation
          headerAddressList={headerAddressList?.userAddresses || []}
          closepopup={setChooseLocationModalOpen}
          setAddAddressPopup={setAddAddressPopup}
        />
      ) : null}

      {addAddressPopup === true ? (
        <AddEditAddress
          setAddAddressPopup={setAddAddressPopup}
          pageName={"topHeader"}
        ></AddEditAddress>
      ) : null}
    </>
  );
};

TopHeader.defaultProps = {
  riyadh: "",
  callusforany: "Call us for any query:",
  userphonenumber: "+966 537775020",
  one: "|",
  en: "EN",
  two: "|",
  Signup_Login: "Signup/Login",
};

export { TopHeader };
