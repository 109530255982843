import Footer from "components/Footer";
import CartProductDetail from "./CartProductDetail";
import CartProductStep from "./CartProductStep";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import * as APIS from "../../utils/helper/Enum";
import { getAuth } from "../../redux/reducers/loginData";
// import { updateOrderSummary } from "../../refvdux/reducers/orderSummary";
import { updateOrderData, getOrderData } from "../../redux/reducers/orderData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { changeTrigger, currentTrigger } from "../../redux/reducers/trigger";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../utils/global/localData";

const CustomCakeCart = () => {
  const { id } = useParams();
  const guestId = CUSTOM.getDeviceID();
  const navigate = useNavigate();
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const orderData = useSelector(getOrderData);
  const setOrderId = useState(0)[1];
  const [orderSummary, setOrderSummary] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [addressList, setAddressList] = useState(null);
  const [zoneList, setZoneList] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [occasionOptions, setOccasionOptions] = useState([]);
  const triggerValue = useSelector(currentTrigger);
  const [isEventSent, setIsEventSent] = useState({
    beginCheckout: false,
    addShippingInfo: false,
    addPaymentInfo: false,
  });

  /**
   * get order details via custom order id
   */
  const getOrderDetails = async (id) => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${APIS?.ORDER?.MY_ORDER_DETAILS}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setOrderDetails(response?.data);
      } else {
        //navigate("/my-order");
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   * get saved message from list
   */
  const getSavedAddress = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        APIS?.USERS?.GET_ALL_ADDRESS,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setAddressList(response?.data);
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   * get picup point list
   */
  const getPickupPoint = async () => {
    let inputSearch = {
      search: "",
      page: 1,
      limit: 500,
      lat: defaultAddress?.lattitude,
      long: defaultAddress?.longitude,
    };
    let listOfZone = [];
    try {
      let response = await globalRequest(
        APIS?.DROP_ZONE?.GET_NEAR_ALL,
        "get",
        {},
        { params: inputSearch },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        listOfZone = response?.data?.data;
      }
    } catch (e) {}
    setZoneList(listOfZone);
  };

  /**
   * get occassion list for user
   */
  const getOccasionOptions = async () => {
    if (auth?.id) {
      try {
        dispatch(changeLoader(true));
        let response = await globalRequest(
          APIS?.OCCASION?.LISTING,
          "get",
          {},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          let relationArray = [];
          response?.data?.data.map((item) => {
            relationArray.push({
              value: item?.id,
              label: item?.name,
            });
          });
          setOccasionOptions(relationArray);
        }
      } catch (e) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: e?.message,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };

  /**
   * get summary api
   */
  const getOrderSummary = async () => {
    let inputData = {
      user_id: auth?.id,
      coupon_code: orderData?.couponCode,
      deliveryType: orderData?.delivery_mode,
      order_id: orderDetails?.orderData?.id,
      delivery_type: orderData?.delivery_type,
    };
    if (
      orderData?.delivery_mode != "buying_for_love" &&
      orderData?.delivery_mode
    ) {
      if (orderData?.delivery_mode == "home_office") {
        inputData.userAddresslat =
          orderData?.home_office?.itemDetail?.lattitude;
        inputData.userAddresslong =
          orderData?.home_office?.itemDetail?.longitude;
        inputData.drop_zone_id = 0;
      } else {
        inputData.userAddresslat = orderData?.home_office?.latitude;
        inputData.userAddresslong = orderData?.home_office?.longitude;
        inputData.drop_zone_id = orderData?.home_office?.id;
        inputData.branch_id = orderData?.timeSloat?.branchId || 0;
      }
    }
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS?.CUSTOMCAKE?.GET_ORDER_SUMMARY,
        "get",
        {},
        { params: inputData },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setOrderSummary(response?.data);
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const sendCheckoutGaEvent = async (eventName) => {
    console.log(orderSummary);
    try {
      const authData = CUSTOM.loginData();
      const response = await globalRequest(
        APIS?.CART?.SEND_CHECKOUT_GA_EVENT,
        "post",
        {
          user_id: authData?.id,
          guest_id: guestId,
          gaData: CUSTOM.getGACookieValues(),
          eventName,
          totalPrice: orderSummary?.total_amount,
        },
        {}
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        console.log("GA event sent");
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  const sendPurchaseGaEvent = async (order_number) => {
    try {
      let response = await globalRequest(
        APIS?.ORDER?.SEND_PURCHASE_GA_EVENT,
        "post",
        { order_number, gaData: CUSTOM.getGACookieValues() },
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        console.log("GA event sent");
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  const removePaymentLocalInfo = () => {
    localStorage.removeItem(storageKeys.REGULAR_ORDER_DATA);
    localStorage.removeItem(storageKeys.REG_ZONE_LIST);
    localStorage.removeItem(storageKeys.REG_ADDRESS_LIST);
    localStorage.removeItem(storageKeys.REG_OCCASION_LIST);
  };

  const addValueLocalStorageToRedux = () => {
    if (localStorage.getItem(storageKeys.REGULAR_ORDER_DATA)) {
      let reg_orderData = addDeleteGetLocalStorage(
        storageKeys.REGULAR_ORDER_DATA,
        {},
        "get"
      );
      let reg_zoneList = addDeleteGetLocalStorage(
        storageKeys.REG_ZONE_LIST,
        {},
        "get"
      );
      let reg_addressList = addDeleteGetLocalStorage(
        storageKeys.REG_ADDRESS_LIST,
        {},
        "get"
      );
      let reg_occasionOptions = addDeleteGetLocalStorage(
        storageKeys.REG_OCCASION_LIST,
        {},
        "get"
      );
      try {
        if (reg_zoneList) {
          setZoneList(JSON.parse(reg_zoneList));
        }
        if (reg_addressList) {
          setAddressList(JSON.parse(reg_addressList));
        }
        if (reg_occasionOptions) {
          setOccasionOptions(JSON.parse(reg_occasionOptions));
        }
        if (reg_orderData) {
          dispatch(updateOrderData(JSON.parse(reg_orderData)));
        }
      } catch (e) {}
      removePaymentLocalInfo();
    }
  };

  /**
   * store & get custom cake order id from url
   */
  useEffect(() => {
    if (id) {
      setOrderId(CUSTOM.base64Encode(id, "decode"));
      getOrderDetails(CUSTOM.base64Encode(id, "decode"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * adress list for auth
   */
  useEffect(() => {
    if (addressList != null && auth?.id) {
      let address = addressList?.userAddresses?.find(
        (item) => item?.id === addressList?.defaultAddressId
      );
      if (address) {
        setDefaultAddress(address);
      } else {
        setDefaultAddress(CUSTOM.getDefautAddress());
      }
    }
  }, [addressList, auth]);

  /**
   * get save addess when load page
   */
  useEffect(() => {
    if (auth?.id) {
      getOccasionOptions();
      getSavedAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  /**
   * get pickup point data
   */
  useEffect(() => {
    if (defaultAddress) {
      getPickupPoint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddress]);

  /**
   * get occasion list
   */
  useEffect(() => {
    if (triggerValue == "loadGiftOccasionList") {
      getOccasionOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * order summary data
   */
  useEffect(() => {
    if (orderDetails) {
      getOrderSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderDetails,
    orderData?.delivery_mode,
    orderData?.delivery_type,
    orderData?.home_office,
    orderData?.timeSloat?.branchId,
  ]);

  /**
   * load address summary data
   */
  useEffect(() => {
    if (triggerValue == "loadSummay") {
      getOrderSummary();
      dispatch(changeTrigger(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * Load address data
   */
  useEffect(() => {
    if (triggerValue == "loadAddressList") {
      getSavedAddress();
      dispatch(changeTrigger(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * load default state data
   */
  useEffect(() => {
    dispatch(updateOrderData({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * user personal details update in state
   */
  useEffect(() => {
    if (auth) {
      let userInputs = {
        first_name: auth?.firstName,
        last_name: auth?.lastName,
        email: auth?.email,
        mobile_no: CUSTOM.getMobileNo(auth?.mobileNo, "number"),
      };
      let updatedOrderData = { ...orderData };
      updatedOrderData.userDetails = userInputs;
      updatedOrderData.pageStep = 2;
      setTimeout(() => {
        dispatch(updateOrderData(updatedOrderData));
      }, [150]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  /**
   * check order status
   */
  useEffect(() => {
    if (
      orderDetails?.orderData?.status != "approved" &&
      orderDetails?.orderData
    ) {
      navigate("/my-order");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  useEffect(() => {
    if (
      triggerValue == "updateinlocalforcustomcake" &&
      Object.keys(orderData).length
    ) {
      removePaymentLocalInfo();
      addDeleteGetLocalStorage(
        storageKeys.REGULAR_ORDER_DATA,
        orderData,
        "add",
        "single"
      );
      addDeleteGetLocalStorage(
        storageKeys.REG_ZONE_LIST,
        zoneList,
        "add",
        "single"
      );
      addDeleteGetLocalStorage(
        storageKeys.REG_OCCASION_LIST,
        occasionOptions,
        "add",
        "single"
      );
      addDeleteGetLocalStorage(
        storageKeys.REG_ADDRESS_LIST,
        addressList,
        "add",
        "single"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  useEffect(() => {
    setTimeout(() => {
      const url = window.location.href;
      const params = new URL(url);
      const payment_status = params.searchParams.get("payment_status");
      if (payment_status !== null) {
        const order_id = params.searchParams.get("order_id");
        const message = params.searchParams.get("message");
        if (payment_status == "success") {
          sendPurchaseGaEvent(order_id);
          CUSTOM.sendPaymentStatusGaEvent(
            "success",
            "order",
            auth?.id,
            orderData?.userDetails
          );
          navigate(`/cart-success/${order_id}?type=custom-cake`);
          removePaymentLocalInfo();
        } else {
          CUSTOM.sendPaymentStatusGaEvent(
            "failed",
            "order",
            auth?.id,
            orderData?.userDetails
          );
          addValueLocalStorageToRedux();
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: message,
              snackbarState: "error",
            })
          );
        }
        let urlEx = url.split("?");
        window.history.pushState({ html: "", pageTitle: "cart" }, "", urlEx[0]);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-gray-50_02 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full">
        <div className="pt-7 pb-36 flex flex-row gap-7 sm:flex-col max-w-[1110px] w-full mx-auto md:px-4 items-start">
          {orderDetails?.orderData?.status == "approved" ? (
            <>
              {" "}
              <CartProductStep
                addressList={addressList}
                zoneList={zoneList}
                occasionOptions={occasionOptions}
                orderDetails={orderDetails}
                orderSummary={orderSummary}
                isEventSent={isEventSent}
                setIsEventSent={setIsEventSent}
                sendCheckoutGaEvent={sendCheckoutGaEvent}
              />
              <CartProductDetail
                orderDetails={orderDetails}
                orderSummary={orderSummary}
              />
            </>
          ) : null}
        </div>
        <Footer className="absolute bottom-[0] flex font-nunitoregular inset-x-[0] items-center justify-center mx-auto w-full" />
      </div>
    </>
  );
};

export default CustomCakeCart;
