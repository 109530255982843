import { useEffect, useState } from "react";
import { Button, Img, Text, Radio } from "components";
import { useTranslation } from "react-i18next";
import SavedAddressesList from "./SavedAddressesList";
import PickUpPointsList from "./PickUpPointsList";
import SendAsAGift from "./SendAsAGift";
import styled from "styled-components";
import {
  updateOrderData,
  getOrderData,
} from "../../../../redux/reducers/orderData";
import { useDispatch, useSelector } from "react-redux";
import { AddEditAddress } from "popups/AddEditAddress";
import { changeTrigger } from "redux/reducers/trigger";

const Tab = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 
border-color: #BD0043 !important;
`}
`;
// const Panal = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) =>
//     isSelected &&
//     `
// display: block;
// `}
// `;

const DeliveryMode = ({
  addressList,
  zoneList,
  occasionOptions,
  isEventSent,
  setIsEventSent,
  sendCheckoutGaEvent,
}) => {
  const orderData = useSelector(getOrderData);
  const [AddAddressPopup, setAddAddressPopup] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleTabClick = (index) => {
    if (!isEventSent?.beginCheckout) {
      setIsEventSent((prev) => ({ ...prev, beginCheckout: true }));
      sendCheckoutGaEvent("begin_checkout");
    }
    dispatch(
      updateOrderData({
        ...orderData,
        delivery_mode: "",
        deliveryModeTabIndex: index,
        delivery_type: "",
        deliveryDateTimeActiveTab: 0,
      })
    );
    //setActiveTab(index);
  };

  useEffect(() => {
    if (orderData?.addAddressPopup) {
      setAddAddressPopup(true);
    }
  }, [orderData?.addAddressPopup]);

  useEffect(() => {
    if (AddAddressPopup == "getAddressList") {
      setAddAddressPopup(false);
      dispatch(changeTrigger("loadAddressList"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddAddressPopup]);

  useEffect(() => {
    setActiveTab(
      orderData?.deliveryModeTabIndex ? orderData?.deliveryModeTabIndex : 0
    );
  }, [orderData?.deliveryModeTabIndex]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        {/* After fill Delivery Mode */}
        {orderData?.pageStep > 2 ? (
          <div className="flex flex-col gap-4 items-center justify-start w-full mb-2">
            <div className="flex flex-row xs:flex-col md:gap-5 items-center xs:items-start justify-start w-full">
              <div className="flex flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {t("deliveryMode")}:{" "}
                  {orderData?.delivery_mode == "buying_for_love"
                    ? t("buyingForALovedOne")
                    : ""}
                  {orderData?.delivery_mode == "pickup_point"
                    ? t("PickUpPoint")
                    : ""}
                  {orderData?.delivery_mode == "home_office" ? t("Home") : ""}
                </Text>

                {orderData?.delivery_mode == "buying_for_love" ? (
                  <>
                    <div className="flex gap-1 flex-row items-center">
                      <Text className="text-black-900 text-sm font-nunitoregular">
                        {orderData?.buying_for_love?.recipient_name}
                      </Text>
                    </div>
                    <div className="flex gap-1 flex-row items-center">
                      <Text className="text-black-900 text-sm nunitoregular">
                        {orderData?.buying_for_love?.country_code}{" "}
                        {orderData?.buying_for_love?.mobile_no} |{" "}
                        {orderData?.buying_for_love?.email}
                      </Text>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex gap-1 flex-row items-center">
                      <Text className="text-black-900 text-sm nunitoregular">
                        {orderData?.delivery_mode == "home_office"
                          ? orderData?.home_office?.address
                          : null}
                        {orderData?.delivery_mode == "pickup_point"
                          ? orderData?.home_office?.address
                          : null}
                      </Text>
                    </div>
                  </>
                )}
              </div>
              <Button
                className="w-auto mx-auto px-3 py-1.5 text-sm sm:mx-0"
                size="sm"
                variant="OutlineBlack"
                onClick={(e) => {
                  dispatch(
                    updateOrderData({
                      ...orderData,
                      pageStep: 2,
                      delivery_mode: "",
                      delivery_type: "",
                    })
                  );
                }}
              >
                {t("change")}
              </Button>
            </div>
          </div>
        ) : null}
        {/* After fill Delivery Mode */}

        {!orderData?.delivery_mode || orderData?.pageStep == 2 ? (
          <div className="flex flex-col gap-4 items-center justify-start w-full mt-0.5 mb-2">
            <div className="flex flex-row md:gap-5 items-center justify-start w-full">
              <div className="flex flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {t("deliveryMode")}
                </Text>
                <div className="flex gap-1 flex-row items-center">
                  <Text className="text-black-900 text-sm font-nunitoregular">
                    {t("forYourItemsChooseTheDeliveryMode")}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {orderData?.pageStep == 2 ? (
          <div className="flex flex-row md:flex-col flex-wrap w-full gap-x-3 md:gap-y-3 mt-[22px] mb-[18px]">
            <Tab
              className="common-pointer bg-white-A700 border border-gray-300 border-solid flex flex-col gap-4 justify-start p-4 w-[32%] md:w-full md:order-1"
              isSelected={activeTab === 1}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex gap-2">
                <Radio
                  name="deliveryMode"
                  checked={activeTab === 1}
                  onChange={() => handleTabClick(1)}
                />
                <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                  {t("homeOfficeOtherDelivery")}
                </Text>
              </div>
              <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                {t("multipleAddressesInThisLocationOrAddANewOne")}
              </Text>
            </Tab>
            <Tab
              className="common-pointer relative bg-white-A700 border border-gray-300 border-solid flex flex-col gap-4 justify-start p-4 w-[32%] md:w-full md:order-3"
              isSelected={activeTab === 2}
              onClick={() => handleTabClick(2)}
            >
              <div className="absolute flex flex-col items-end justify-start -right-2 rtl:right-auto rtl:-left-2 top-[6%] w-[39%]">
                <div
                  className="bg-cover bg-no-repeat flex flex-col h-6 items-end justify-start p-1 w-auto pl-4 rtl:rotate-180"
                  style={{
                    backgroundImage: "url('/images/img_group425.svg')",
                  }}
                >
                  <Text className="mr-[7px] text-white-A700 text-xs font-nunitoregular whitespace-nowrap rtl:rotate-180">
                    {t("bestOffer")}
                  </Text>
                </div>
                <Img
                  className="h-2 w-2"
                  src="/images/img_signal_pink_900.svg"
                  alt="signal"
                />
              </div>
              <div className="flex gap-2">
                <Radio
                  name="deliveryMode"
                  checked={activeTab === 2}
                  onChange={() => handleTabClick(2)}
                />
                <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                  {t("pickUp")} <br className="md:hidden" />
                  {t("point")}
                </Text>
              </div>
              <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                {t(
                  "getDiscountOnYourOrderByPickingANearestDropZoneAsDeliveryLocation"
                )}
              </Text>
            </Tab>
            <Tab
              className="common-pointer bg-light_blue-50 flex flex-col gap-4 justify-start p-4 bg-no-repeat ltr:bg-right-top rtl:bg-left-top bg-auto w-[32%] border border-solid border-light_blue-50 md:w-full md:order-5"
              isSelected={activeTab === 3}
              onClick={() => handleTabClick(3)}
              style={{
                backgroundImage: "url('images/img_group710.svg')",
              }}
            >
              <div className="flex gap-2">
                <Radio
                  name="deliveryMode"
                  checked={activeTab === 3}
                  onChange={() => handleTabClick(3)}
                />
                <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                  {t("buyingForALovedOne")}
                </Text>
              </div>
              <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                {t("sendAGiftAndPersonalizedMessageOrSong")}
              </Text>
            </Tab>
            {activeTab == 1 ? (
              <div className="md:order-2 w-full">
                <SavedAddressesList
                  addressList={addressList}
                  isEventSent={isEventSent}
                  setIsEventSent={setIsEventSent}
                  sendCheckoutGaEvent={sendCheckoutGaEvent}
                />
              </div>
            ) : null}
            {activeTab == 2 ? (
              <div className="md:order-4 w-full">
                <PickUpPointsList
                  zoneList={zoneList}
                  isEventSent={isEventSent}
                  setIsEventSent={setIsEventSent}
                  sendCheckoutGaEvent={sendCheckoutGaEvent}
                />
              </div>
            ) : null}
            {activeTab == 3 ? (
              <div className="md:order-6 w-full">
                <SendAsAGift
                  occasionOptions={occasionOptions}
                  isEventSent={isEventSent}
                  setIsEventSent={setIsEventSent}
                  sendCheckoutGaEvent={sendCheckoutGaEvent}
                />
              </div>
            ) : null}
            {AddAddressPopup ? (
              <AddEditAddress
                setAddAddressPopup={setAddAddressPopup}
                orderData={orderData}
                pageName={"cart"}
              ></AddEditAddress>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DeliveryMode;
